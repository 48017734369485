export const cartDrawerVisible = ref(false)
export const cartDrawerGameKey = ref<Games.GameKeyLax>('')
export const cartDrawerGaContext = ref<GaUtils.GaContext>({})
export function openCartDrawer(gameKey: Games.GameKeyLax, context: GaUtils.GaContext) {
  cartDrawerVisible.value = true
  cartDrawerGameKey.value = gameKey || ''
  cartDrawerGaContext.value = context
}
export function closeCartDrawer() {
  cartDrawerVisible.value = false
  cartDrawerGameKey.value = ''
  cartDrawerGaContext.value = {}
}
