import type { LoginContext, LoginMethods } from '@/compositions/gaUtils'
import type { Token } from '@/storeModules/game'
import type { AxiosError, AxiosResponse } from 'axios'
import { GameKeyEnum } from '@/types/enums'
import { jwtDecode } from 'jwt-decode'

export const visibilityLogInDialog = ref(false)
export const logInGameKey = ref<Games.GameKeyLax>(null)
export function setLoginGaContext(context: LoginContext | null): void {
  localStorage?.setItem('gaLoginContext', context ? JSON.stringify(context) : '')
}
export function getLoginGaContext(): LoginContext {
  const gaLoginContextValue = localStorage?.getItem('gaLoginContext')
  let context: LoginContext = {}
  if (gaLoginContextValue) {
    try {
      context = JSON.parse(gaLoginContextValue)
    } catch (e) {
      localStorage?.setItem('gaLoginContext', '')
      console?.error(e)
    }
  }
  return context
}
export const accordionModel = ref<LoginMethods>(gaUtils.gaLoginMethods.goat)
export function openLoginDialog(gameKey: Games.GameKeyLax = null, context: LoginContext, showMethod: LoginMethods = gaUtils.gaLoginMethods.goat): void {
  setLoginGaContext(context)
  visibilityLogInDialog.value = true
  logInGameKey.value = gameKey || null
  accordionModel.value = showMethod
}
export function closeLoginDialog(clearContext = true): void {
  visibilityLogInDialog.value = false
  logInGameKey.value = null
  if (clearContext) {
    setLoginGaContext(null)
  }
}
export const showHowMode = ref(false)
export const callBacks = {
  paste: (): void => {}
}

export interface AuthState {
  token?: string
  __token: string
  email?: string
  games?: Record<Games.GameKeyType, string>
  id?: string
  valid?: string
  com?: boolean
}
export const oldTokens = ref<string[]>([])
export function decodeGoatToken(goadToken: string, email: string): AuthState {
  let tokenData: AuthState = { __token: goadToken }
  try {
    const decoded: AuthState = jwtDecode(String(goadToken))
    tokenData = {
      token: decoded?.id ? goadToken : '',
      __token: goadToken,
      email,
      games: decoded?.games ?? {},
      id: decoded?.id ?? '',
      valid: decoded?.valid ?? '',
      com: decoded?.com || false
    }
    if (tokenData.token?.length) {
      const { clearGameTokens, setGameToken } = gamesUtils
      clearGameTokens()
      setGameToken(GameKeyEnum.goat, tokenData as Token)
      const connectedGames = Object.entries(tokenData?.games ?? {})
      if (connectedGames.length) {
        connectedGames.forEach(([keyName, playerId]) => {
          if (keyName in GameKeyEnum) {
            const gameKey = GameKeyEnum[keyName as keyof typeof GameKeyEnum]
            const gameTokenData = {
              ...{ id: '', name: '', token: '', valid: '', __token: '' },
              token: tokenData?.token || '',
              __token: goadToken,
              id: playerId,
              name: '',
              valid: tokenData?.valid || ''
            }
            setGameToken(gameKey, gameTokenData as Token)
          }
        })
      }
    }
  } catch (err) {
    const { logOut } = gamesUtils
    logOut(GameKeyEnum.goat)
    console?.error?.('not decoded jwt', err, goadToken, email)
  }
  oldTokens.value = []
  return tokenData
}
export const forgotPasswordMode = ref('')
export enum ForgotPasswordSteps {
  step1 = 'step1',
  step2 = 'step2'
}
export const registerMode = ref('')
export const confirmedEmail = ref('')
export const serviceName = ref('')
export const serviceToken = ref('')
export const canBeUpgraded = computed(() => {
  const { isLoggedGoat, isLoggedGame } = gamesUtils
  return !isLoggedGoat.value && isLoggedGame(logInGameKey.value || '')
})
export enum RegisterModeActions {
  register = 'register',
  confirmEmail = 'confirmEmail',
  acceptTerms = 'acceptTerms'
}
export const goatBenefits = [
  { header: 'Gamesture One Account (GOAT)', body: 'Set one account using your email and manage and organize all of your characters!' },
  { header: 'One GOAT account to rule them all!', body: 'Collect GCoins and spent them on everything you need!' },
  { header: 'Get special bonuses and freebies!', body: 'Get exclusive offers and collect loyalty points' },
  { header: 'We will never use your data without your permission!', body: 'We treat this very seriously. You won’t be spammed... unless you want to!' }
]
export async function autoLoginGoatFromGameAccount(gameToken: string) {
  const app = utilsPlugin.getApp()
  let tokenData: AuthState = { __token: '' }
  await app?.$axios.get(`${gamesUtils.getGoatApiUrl('logged/alive')}`, { params: { email: 1 }, headers: {
    Authorization: gameToken
  } }).then((response: AxiosResponse) => {
    if (response?.data?.status === 'ok') {
      if (response?.headers?.token?.length && response?.data?.email?.length && response?.data?.id) {
        tokenData = decodeGoatToken(response.headers.token, response?.data?.email || '')
      }
      gamesUtils.setLoyalty(GameKeyEnum.goat, response?.data)
      gamesUtils.goatDebugCheckTokens()
    }
  }).catch((err: AxiosError) => {
    if (!(err?.response?.status === 401 || err?.status === 401)) {
      gamesUtils.catchError(err)
    }
  })
  return !!tokenData.id
}
