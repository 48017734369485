import { utilsPlugin } from '@/plugins/utilsPlugin'
import { GameKeyEnum } from '@/types/enums'

const extraParams: Record<string, string> = {}
function onReadyVueGtag(gtagId: string) {
  let targetClientID = localStorage?.getItem?.('gs_client_id') || ''
  if (!targetClientID) {
    window?.gtag('get', gtagId, 'client_id', (clientId: string) => {
      targetClientID = clientId
      if (!clientId?.length) {
        function generateDeviceId() {
          const rawData = `${navigator?.userAgent}-${Date.now()}-${Math.random()}-${navigator?.language}`
          return btoa(rawData)?.slice(0, 22)
        }
        if (!targetClientID.length) {
          targetClientID = generateDeviceId()
        }
      }
      localStorage?.setItem?.('gs_client_id', targetClientID)
      extraParams.gs_client_id = targetClientID
    })
  } else {
    extraParams.gs_client_id = targetClientID
  }
}

function gaEvent(eventName: string, params: GaUtils.GaContext) {
  let idUser = ''
  if ((utilsPlugin.siteGtag() || import.meta.env.VITE_DEBUG_GA)) {
    // ssg throw error when used gamesUtils
    if (utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)?.id) {
      idUser = `${GameKeyEnum.goat}_${utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)?.id}`
    } else if (params.gs_game && utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(params.gs_game)?.id) {
      idUser = `${params.gs_game}_${utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(params.gs_game)?.id}`
    }
  }
  if (utilsPlugin.siteGtag()) {
    utilsPlugin.siteGtag()?.event(eventName, {
      ...params,
      ...extraParams,
      ...(params?.gs_user_id ? { gs_user_id: params.gs_user_id } : (idUser ? { gs_user_id: idUser } : {}))
    })
  }
  if (import.meta.env.VITE_DEBUG_GA) {
    // eslint-disable-next-line no-console
    console?.log({ eventName, params: {
      ...params,
      ...extraParams,
      ...(params?.gs_user_id ? { gs_user_id: params.gs_user_id } : (idUser ? { gs_user_id: idUser } : {}))
    } }, 'VITE_DEBUG_GA')
  }
}
enum gaSections {
  btnLogin = 'btnLogin',
  banner = 'banner',
  bestDeals = 'bestDeals',
  offersList = 'offersList',
  free = 'free',
  previewOffer = 'previewOffer',
  previewOffersList = 'previewOffersList',
  loginBanner = 'loginBanner',
  pageOffer = 'pageOffer',
  pageOffersList = 'pageOffersList',
  cart = 'cart',
  cartChecker = 'cartChecker',
  header = 'header',
  userBanner = 'userBanner',
  userModal = 'userModal',
  userPopup = 'userPopup',
  userBox = 'userBox',
  mobileMenu = 'mobileMenu',
  buyBtn = 'buyBtn',
  upgradeBanner = 'upgradeBanner',
  gaotPage = 'gaotPage',
  paymentDialog = 'paymentDialog'
}
enum gaLoginMethods {
  gameToken = 'gameToken',
  deepLink = 'deepLink',
  goat = 'goat',
  goatLink = 'goatLink'
}
enum gaHelperEnums {
  gcoin = 'GCOIN',
  free = 'FREE'
}
export type LoginMethods = `${gaLoginMethods}`
interface ItemsContext {
  item_id?: string
  item_name?: string
  index?: number // position of the item in the list
  quantity?: number
  price?: number
}
export interface LoginContext extends GaUtils.GaContext {
  method?: string
  connectMode?: boolean
}
function gaLogin(params: LoginContext): void {
  const gsContext: string[] = []
  if (params.method) {
    gsContext.push(params.method)
  }
  gsContext.push(utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)?.id ? 'goatAccount' : 'gameAccount')
  if (params?.gs_context) {
    gsContext.push(params.gs_context)
  }
  params.gs_context = gsContext.join(',')

  gaEvent('login', params)
}
export interface AddToCartContext extends GaUtils.GaContext {
  currency?: string
  value?: number
  items?: ItemsContext[]
}
function gaAddToCart(params: AddToCartContext): void {
  gaEvent('add_to_cart', params)
}

export interface RemoveFromCartContext extends GaUtils.GaContext {
  currency?: string
  value?: number
  items?: ItemsContext[]
}
function gaRemoveFromCart(params: RemoveFromCartContext) {
  gaEvent('remove_from_cart', params)
}
export interface ViewCartContext extends GaUtils.GaContext {
  currency?: string
  value?: number
  items?: ItemsContext[]
}
function gaViewCart(params: ViewCartContext) {
  gaEvent('view_cart', params)
}
function gaConnectBtnClick(params: GaUtils.GaContext) {
  gaEvent('connect_btn_click', params)
}
enum gaSignUpMethods {
  gmail = 'gmail',
  facebook = 'facebook',
  goatForm = 'goatForm'
}
export type SignUpMethods = `${gaSignUpMethods}` | string
export interface SignUpContext extends GaUtils.GaContext {
  method?: SignUpMethods
}
function gaSignUp(params: SignUpContext) {
  gaEvent('sign_up', params)
}
enum gaBuyBtnContext {
  goat_account = 'goat_account',
  game_account = 'game_account',
  connection_game_required = 'connection_game_required',
  not_logged_in = 'not_logged_in',
  offerExpired = 'offerExpired',
  offerBought = 'offerBought',
  offerLocked = 'offerLocked',
  offerFree = 'offerFree',
  showStorePaymentDialog = 'showStorePaymentDialog',
  beginCheckout = 'beginCheckout'
}
export interface BuyBtnClickContext extends GaUtils.GaContext {
  currency?: string
  value?: number
  items?: ItemsContext[]
}
function gaBuyBtnClick(params: BuyBtnClickContext): void {
  gaEvent('buy_btn_click', params)
}
function gaBeginCheckout(params: BuyBtnClickContext): void {
  gaEvent('begin_checkout', params)
}
function gaPurchase(params: BuyBtnClickContext): void {
  gaEvent('purchase', params)
}
export interface SpendVirtualCurrencyContext extends GaUtils.GaContext {
  virtual_currency_name?: string
  value?: number
  item_name?: string
  items?: ItemsContext[]
}
function gaSpendVirtualCurrency(params: SpendVirtualCurrencyContext): void {
  gaEvent('spend_virtual_currency', params)
}
function gaViewItem(params: BuyBtnClickContext): void {
  gaEvent('view_item', params)
}
function gaVerifyBtnClick(params: GaUtils.GaContext) {
  gaEvent('verify_btn_click', params)
}
function gaUpgradeBtnClick(params: GaUtils.GaContext) {
  gaEvent('upgrade_btn_click', params)
}
const gaUtils = {
  gaLogin,
  gaLoginMethods,
  gaSections,
  gaAddToCart,
  gaRemoveFromCart,
  gaViewCart,
  gaConnectBtnClick,
  gaSignUp,
  gaSignUpMethods,
  gaBuyBtnClick,
  gaBuyBtnContext,
  gaBeginCheckout,
  gaPurchase,
  gaSpendVirtualCurrency,
  gaHelperEnums,
  gaViewItem,
  onReadyVueGtag,
  gaVerifyBtnClick,
  gaUpgradeBtnClick
}
export { gaUtils }
