export function getNumericEnumKeyByVal<T extends { [index: string | number]: string | number }>(e_num: T, val: number | string) {
  if (e_num[val] === undefined) {
    return undefined
  }
  return e_num[val] as keyof typeof e_num
}

export enum LevelStatus {
  SILVER,
  GOLD,
  DIAMOND
}

export enum GameKeyEnum {
  gang = 'gang',
  slashAndRoll = 'slashAndRoll',
  soccerClash = 'soccerClash',
  questland = 'questland',
  mafia = 'mafia',
  goat = 'goat'
}
