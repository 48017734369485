const visibilityConnectDialog = ref(false)
const connectionDialogGameKey = ref<Games.GameKeyLax>(null)
const connectionDialogBuyMode = ref(false)
function openConnectionDialog(gameKey: Games.GameKeyLax, buyMode = false) {
  connectionDialogGameKey.value = gameKey
  visibilityConnectDialog.value = true
  connectionDialogBuyMode.value = buyMode
}

export {
  connectionDialogBuyMode,
  connectionDialogGameKey,
  openConnectionDialog,
  visibilityConnectDialog
}
