import type { LoginContext } from '@/compositions/gaUtils'
import type { Token } from '@/storeModules/game'
import type { AxiosResponse } from 'axios'
import type { NotificationOptions } from 'element-plus'
import EnergyIconGangUrl from '@/assets/games/gang/gang_energy.png'
import GemIconGangUrl from '@/assets/games/gang/gang_gems.png'
import gameIconGangUrl from '@/assets/games/gang/gang_icon.jpg?url'
import connectTipGangUrl_1 from '@/assets/games/gang/goat_login_tut_gang1.webp'
import connectTipGangUrl_2 from '@/assets/games/gang/goat_login_tut_gang2.webp'
import LogInBannerBgGangUrl from '@/assets/games/gang/log_in_banner_bg.jpg?url'
import LogInBannerIconGangUrl from '@/assets/games/gang/log_in_banner_icon.png?url'
import logoGangUrl from '@/assets/games/gang/logo_TG.png?url'
import LogInGangUrl from '@/assets/games/gang/shop_code_tip_tg.jpg?url'
import gameBannerGangUrl from '@/assets/games/gang/TG_1200-min.jpeg?url'
import gameBannerMafiaUrl from '@/assets/games/mafia/banner_1200-min.jpg'
import EnergyIconMafiaUrl from '@/assets/games/mafia/energy.png'
import GemIconMafiahUrl from '@/assets/games/mafia/gem.png'
import connectTipMafiaUrl_1 from '@/assets/games/mafia/goat_login_tut_mafia1.webp'
import connectTipMafiaUrl_2 from '@/assets/games/mafia/goat_login_tut_mafia2.webp'
import gameIconMafiaUrl from '@/assets/games/mafia/icon.jpg'
import LogInBannerBgMafiaUrl from '@/assets/games/mafia/log_in_banner_bg.jpg?url'
import LogInBannerIconMafiaUrl from '@/assets/games/mafia/log_in_banner_icon.png?url'
import logoMafiaUrl from '@/assets/games/mafia/logo.png'
import logInTipMafiaUrl from '@/assets/games/mafia/shop_code_tip.jpg'
import connectTipQlUrl_1 from '@/assets/games/questland/goat_login_tut_ql1.webp'
import connectTipQlUrl_2 from '@/assets/games/questland/goat_login_tut_ql2.webp'
import LogInBannerBgQlhUrl from '@/assets/games/questland/log_in_banner_bg.jpg?url'
import LogInBannerIconQlUrl from '@/assets/games/questland/log_in_banner_icon.png?url'
import logoQlUrl from '@/assets/games/questland/logo_QL.png'
import gameBannerQlUrl from '@/assets/games/questland/QL_1200-min.jpeg'
import GemIconQlUrl from '@/assets/games/questland/ql_gem.png?url'
import gameIconQlUrl from '@/assets/games/questland/ql_icon.jpg'
import logInTipQlUrl from '@/assets/games/questland/shop_code_tip_ql.jpg'
import connectTipSlashAndRollUrl_1 from '@/assets/games/slashAndRoll/goat_login_tut_slash1.webp'
import connectTipSlashAndRollUrl_2 from '@/assets/games/slashAndRoll/goat_login_tut_slash2.webp'
import LogInBannerBgSlashAndRollUrl from '@/assets/games/slashAndRoll/log_in_banner_bg.jpg?url'
import LogInBannerIconSlashAndRollUrl from '@/assets/games/slashAndRoll/log_in_banner_icon.png?url'
import logoSlashAndRollUrl from '@/assets/games/slashAndRoll/logo_SnR.png?url'
import LogInSlashAndRollUrl from '@/assets/games/slashAndRoll/shop_code_tip_snr.jpg?url'
import gameBannerSlashAndRollUrl from '@/assets/games/slashAndRoll/SnR_1200-min.jpeg?url'
import EnergyIconSlashAndRollUrl from '@/assets/games/slashAndRoll/snr_energy.png'
import GemIconSlashAndRollUrl from '@/assets/games/slashAndRoll/snr_gem.png'
import gameIconSlashAndRollUrl from '@/assets/games/slashAndRoll/sr_icon.jpg?url'
import connectTipSoccerClashlUrl_1 from '@/assets/games/soccerClash/goat_login_tut_soccer_1.webp'
import connectTipSoccerClashlUrl_2 from '@/assets/games/soccerClash/goat_login_tut_soccer_2.webp'
import LogInBannerBgSoccerClashUrl from '@/assets/games/soccerClash/log_in_banner_bg.jpg?url'
import LogInBannerIconSoccerClashUrl from '@/assets/games/soccerClash/log_in_banner_icon.png?url'
import logoSoccerClashlUrl from '@/assets/games/soccerClash/logo_soccer.png?url'
import EnergyIconSoccerClashUrl from '@/assets/games/soccerClash/sc_energy.png'
import GemIconSoccerClashUrl from '@/assets/games/soccerClash/sc_gems.png'
import LogInSoccerClashlUrl from '@/assets/games/soccerClash/shop_code_tip.jpg?url'
import gameBannerSoccerClashlUrl from '@/assets/games/soccerClash/Soccer_1200-min.jpg?url'
import gameIconSoccerClashlUrl from '@/assets/games/soccerClash/socer_icon .jpg?url'
import { cartDrawerGaContext } from '@/components/games/cartDrawer/CartDrawerCompose'
import { buyFreeDialog } from '@/components/layout/BuyFreeDialog/BuyFreeDialogCompose'
import { decodeGoatToken, openLoginDialog } from '@/components/layout/LoginDialog/LoginDialog'
import { visibilityPaymentMethod } from '@/components/layout/PaymentMethod/PaymentMethodCompose'

import { payPro, url } from '@/components/layout/PayPro/PayProCompose'
import { openConnectionDialog } from '@/components/shared/GoatConnectGameDialog/GoatConnectGameDialog'
import { offerDialog, showOfferUrl } from '@/components/shared/OfferDialog/OfferDialogCompose'
import { utilsPlugin } from '@/plugins/utilsPlugin'
import { GameKeyEnum } from '@/types/enums'
import * as Sentry from '@sentry/vue'
import { useDocumentVisibility } from '@vueuse/core'
import axios, { AxiosError } from 'axios'
import { ElNotification } from 'element-plus'

interface BuyContext {
  gaMethod: (params: Record<string, any>) => void
  params: Record<string, any>
}

// eslint-disable-next-line unused-imports/no-unused-vars
function isVisible(id: string) {
  const isSsr = import.meta.env.SSR
  if (!isSsr) {
    return localStorage?.getItem?.('id-visible') === id
  }
  return true
}

const isProd = import.meta.env?.VITE_PROD === 'on'

const games: Games.GamesType = {
  gang: {
    id: 'the-gang',
    idApp: 'gang',
    name: 'The Gang',
    order: 1,
    api: isProd ? 'https://prod.gang-api-gamesture.com' : 'https://dev.gang-api-gamesture.com',
    logo: logoGangUrl,
    icon: gameIconGangUrl,
    gameBanner: gameBannerGangUrl,
    active: true,
    appStore: 'https://apps.apple.com/app/the-gang-street-wars/id1599240652',
    googleStore: 'https://play.google.com/store/apps/details?id=com.gamesture.thegang',
    logInImg: [LogInGangUrl],
    connectInImg: [connectTipGangUrl_1, connectTipGangUrl_2],
    route: { path: '', name: '' },
    gameKey: GameKeyEnum.gang,
    seoDescriptionGame: 'Gear up with The Gang: Your One-Stop Mobile Gaming Shop!',
    seoDescriptionOffer: 'The Gang: Exclusive In-Game Bundles Await!',
    logInBanner: {
      icon: LogInBannerIconGangUrl,
      bg: LogInBannerBgGangUrl,
      bgColor: '#02232e',
      customClass: {
        vertical: 'h-[180%] left-1/2 -translate-x-1/2 -translate-y-8',
        horizontal: 'big:h-[150%] big:-translate-y-5'
      }
    },
    energy: {
      url: EnergyIconGangUrl,
      customClass: 'w-5 -left-2'
    },
    gems: {
      url: GemIconGangUrl,
      customClass: 'w-6 -left-3'
    },
    itemIconClass: '',
    enableCart: true,
    imgStorageUrl: 'https://storage.googleapis.com/bucket-gang-files/',
    gemTabName: 'Gems'
  },
  slashAndRoll: {
    id: 'slash-and-roll',
    idApp: 'slash-and-roll',
    name: 'Slash&Roll',
    order: 2,
    api: isProd ? 'https://prod.sw-api-gamesture.com' : 'https://dev.sw-api-gamesture.com',
    logo: logoSlashAndRollUrl,
    icon: gameIconSlashAndRollUrl,
    gameBanner: gameBannerSlashAndRollUrl,
    active: true,
    appStore: 'https://apps.apple.com/app/id1566838404',
    googleStore: 'https://play.google.com/store/apps/details?id=com.gamesture.team.guild.raids.pvp.multiplayer',
    logInImg: [LogInSlashAndRollUrl],
    connectInImg: [connectTipSlashAndRollUrl_1, connectTipSlashAndRollUrl_2],
    route: { path: '', name: '' },
    gameKey: GameKeyEnum.slashAndRoll,
    seoDescriptionGame: 'Slash and Roll Store: Level Up Your Gaming Style!',
    seoDescriptionOffer: 'Slash&Roll: Exclusive In-Game Bundles Await!',
    logInBanner: {
      icon: LogInBannerIconSlashAndRollUrl,
      bg: LogInBannerBgSlashAndRollUrl,
      bgColor: '#003867',
      customClass: {
        vertical: undefined,
        horizontal: undefined
      }
    },
    energy: {
      url: EnergyIconSlashAndRollUrl,
      customClass: 'w-6 -left-3'
    },
    gems: {
      url: GemIconSlashAndRollUrl,
      customClass: 'w-6 -left-3'
    },
    itemIconClass: '',
    enableCart: true,
    imgStorageUrl: 'https://storage.googleapis.com/bucket-sw-files/',
    gemTabName: 'Gems'
  },
  soccerClash: {
    id: 'soccer-clash',
    idApp: 'soccer-clash',
    name: 'Soccer Clash',
    order: 3,
    api: isProd ? 'https://soccer-prod.netto-api-gamesture.com' : 'https://soccer-dev.netto-api-gamesture.com',
    logo: logoSoccerClashlUrl,
    icon: gameIconSoccerClashlUrl,
    gameBanner: gameBannerSoccerClashlUrl,
    active: true,
    appStore: 'https://apps.apple.com/app/soccer-clash-football-game/id6470202226',
    googleStore: 'https://play.google.com/store/apps/details?id=com.gamesture.soccer.clash.football.world.sport.league',
    logInImg: [LogInSoccerClashlUrl],
    connectInImg: [connectTipSoccerClashlUrl_1, connectTipSoccerClashlUrl_2],
    route: { path: '', name: '' },
    gameKey: GameKeyEnum.soccerClash,
    seoDescriptionGame:
      'Join Soccer Clash today and be the world champion you dreamed! Step into the grand arena of Soccer Clash, a top-tier football simulator that embarks you on a riveting football career. Rise from backyard football skirmishes to European Championships, touching the zenith of pro football stardom.',
    seoDescriptionOffer: 'Soccer Clash: Exclusive In-Game Bundles Await!',
    logInBanner: {
      icon: LogInBannerIconSoccerClashUrl,
      bg: LogInBannerBgSoccerClashUrl,
      bgColor: '#003b26',
      customClass: {
        vertical: 'h-[130%] left-1/2 -translate-x-1/2 -translate-y-2.5',
        horizontal: 'big:h-[140%] big:-translate-y-3.5'
      }
    },
    energy: {
      url: EnergyIconSoccerClashUrl,
      customClass: 'w-4 -left-2'
    },
    gems: {
      url: GemIconSoccerClashUrl,
      customClass: 'w-6 -left-3'
    },
    itemIconClass: '',
    enableCart: true,
    imgStorageUrl: 'https://storage.googleapis.com/bucket-soccer-files/',
    gemTabName: 'Bucks'
  },
  questland: {
    id: 'questland',
    idApp: 'questland',
    name: 'Questland',
    order: 4,
    api: isProd ? 'https://prod.ql-api-gamesture.com' : 'https://dev.ql-api-gamesture.com',
    logo: logoQlUrl,
    icon: gameIconQlUrl,
    gameBanner: gameBannerQlUrl,
    active: true,
    appStore: 'https://apps.apple.com/pl/app/questland-turn-based-rpg/id1079884680',
    googleStore: 'https://play.google.com/store/apps/details?id=com.gamesture.questland',
    logInImg: [logInTipQlUrl],
    connectInImg: [connectTipQlUrl_1, connectTipQlUrl_2],
    route: { path: '', name: '' },
    gameKey: GameKeyEnum.questland,
    seoDescriptionGame:
      'HIGHLY ADDICTIVE FANTASY GAME!\n' +
      'A FIRST-PERSON PERSPECTIVE RPG WITH ELEMENTS OF A DUNGEON CRAWLER. AN IMMERSIVE MOBILE EXPERIENCE THAT CARRIES THE LEGACY OF GAMES WE PLAYED AS KIDS.\n' +
      'Collect exquisite gear, customize your hero with hundreds of looks, and upgrade your way to the top with powerful Orbs and enhancements. Fight epic monsters, duel other players online, create Guilds, and more in this incredibly dynamic turn-based action game with CCG elements!',
    seoDescriptionOffer: 'Questland: Exclusive In-Game Bundles Await!',
    logInBanner: {
      icon: LogInBannerIconQlUrl,
      bg: LogInBannerBgQlhUrl,
      bgColor: '#08203B',
      customClass: {
        vertical: 'h-[110%]  left-1/2 -translate-x-1/2',
        horizontal: 'big:h-[90%]  big:translate-y-1'
      }
    },
    energy: {
      url: '',
      customClass: ''
    },
    gems: {
      url: GemIconQlUrl,
      customClass: 'w-6 -left-3'
    },
    itemIconClass: 'scale-150',
    enableCart: true,
    imgStorageUrl: 'https://storage.googleapis.com/ql4-files-eu/',
    gemTabName: 'Gems'
  },
  mafia: {
    id: 'mafia',
    idApp: 'mafia',
    name: 'Mafia Rivals',
    order: 5,
    api: isProd ? 'https://mafia-prod.netto-api-gamesture.com' : 'https://mafia-dev.netto-api-gamesture.com',
    logo: logoMafiaUrl,
    icon: gameIconMafiaUrl,
    gameBanner: gameBannerMafiaUrl,
    active: true,
    appStore: 'https://apps.apple.com/app/mafia-rivals-grand-wars/id6478916867',
    googleStore: 'https://play.google.com/store/apps/details?id=com.gamesture.mafia',
    logInImg: [logInTipMafiaUrl],
    connectInImg: [connectTipMafiaUrl_1, connectTipMafiaUrl_2],
    route: { path: '', name: '' },
    gameKey: GameKeyEnum.mafia,
    seoDescriptionGame:
        'Enter the Mafia life, make the grand city your own!\n' +
        'Build your mafia family. Make your own rules. Take over the city. Time to step in and let the grand mafia game begin, Mafioso!\n',
    seoDescriptionOffer: 'Mafia Rivals: Exclusive In-Game Bundles Await!',
    logInBanner: {
      icon: LogInBannerIconMafiaUrl,
      bg: LogInBannerBgMafiaUrl,
      bgColor: '#02232e',
      customClass: {
        vertical: 'h-[180%] left-1/2 -translate-x-1/2 -translate-y-8',
        horizontal: 'big:h-[150%] big:-translate-y-5'
      }
    },
    energy: {
      url: EnergyIconMafiaUrl,
      customClass: 'w-6 -left-3'
    },
    gems: {
      url: GemIconMafiahUrl,
      customClass: 'w-6 -left-3'
    },
    itemIconClass: '',
    enableCart: true,
    imgStorageUrl: 'https://storage.googleapis.com/bucket-mafia-files/',
    gemTabName: 'Gold'
  }
}
Object.entries(games).forEach(([, game]) => {
  game.route = { path: `/game/${game.id}`, name: 'game-page' }
})

const gameOrder = Object.entries(games)
  .sort((a, b) => a[1].order - b[1].order)
  .map(([gameKey]) => gameKey as Games.GameKeyType)

const listById = Object.fromEntries(Object.entries(games).map(([, game]) => [game.id, game]))

const routeGame = computed<Games.GameItem | null>(() => {
  return listById?.[String(utilsPlugin.getApp()?.$route?.params?.gameID)] || null
})

function isLoggedGame(gameKey: Games.GameKeyType) {
  return !!utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(gameKey)?.token
}

function isConnectedAllToGoat(gameKey: Games.GameKeyType) {
  if (gameKey === GameKeyEnum.goat) {
    const tokens = getGameTokens()
    return !Object.values(games).some((gameSet) => {
      if (!gameSet.active || gameSet.gameKey === GameKeyEnum.goat) {
        return false
      }
      return !tokens?.[gameSet.gameKey]?.token
    })
  } else {
    return isLoggedGame(gameKey)
  }
}
function isSomeConnectedToGoat(gameKey: Games.GameKeyType) {
  if (gameKey === GameKeyEnum.goat) {
    return Object.entries(getGameTokens()).some(([keyOfGame, tokenData]) => {
      if (keyOfGame === GameKeyEnum.goat) {
        return false
      }
      return tokenData?.token?.length > 0
    })
  } else {
    return isLoggedGame(gameKey)
  }
}

/**
 * only to use on gamePages;
 */
const isLogged = computed(() => {
  return !!utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(routeGame.value?.gameKey)?.token
})
const isLoggedGoat = computed(() => {
  return !!utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)?.token
})
const getGoatData = computed<Token>(() => {
  return utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)
})
const gameLoggedOrder = computed(() => {
  return [...gameOrder].sort((a, b) => {
    return (utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(b) ? 1 : 0) - (utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(a) ? 1 : 0)
  })
})

const loading = ref('')

const { gaRemoveFromCart, gaSections } = gaUtils

function getGameSettings(gameKey: Games.GameKeyLax): Games.GameItem | null {
  if (gameKey) {
    return games?.[gameKey] || null
  }
  return null
}

function setGameToken(gameKey: Games.GameKeyLax, token: Token | null) {
  if (gameKey) {
    utilsPlugin.getApp()?.$store?.commit?.('game/setToken', [gameKey, token])
  }
}

function getGameToken(gameKey: Games.GameKeyLax): Token | null {
  if (gameKey) {
    return utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(gameKey) || null
  }
  return null
}

function getGameTokens(): Record<string, Token> {
  return utilsPlugin.getApp()?.$store?.getters?.['game/tokens']
}

function getOldGameTokens(): string[] {
  const allTokensData = getGameTokens()
  return Object.entries(allTokensData).map(([_gameKey, tokenData]) => {
    return tokenData?.token || ''
  }).filter(([gameKey, token]) => {
    return gameKey !== GameKeyEnum.goat && token?.length
  })
}

function clearGameTokens() {
  utilsPlugin.getApp()?.$store?.commit?.('game/clearTokens')
}

function buildDeepLink(idApp: string, routeName: string, gameKey: string, offerID: string | number) {
  const mode = import.meta.env.VITE_PROD === 'on' ? '' : 'dev-'
  let domain = ''
  if (window.location.hostname === 'localhost') {
    domain = `http://${window.location.host}`
  } else {
    domain = `${window.location.protocol}//${mode.length ? 'gamesture-store-dev-log.vercel.app' : 'www.store.gamesture.com'}`
  }
  return `${domain}/deep-link/game/${mode}${idApp}?route=${routeName}&gameKey=${gameKey || ''}&offerId=${offerID || ''}`
}

const gameLoyalty = computed<Games.Lax<Games.Loyalty>>(() => {
  return utilsPlugin.getApp()?.$store?.getters?.['game/loyalty'](routeGame.value?.gameKey)
})
const goatLoyalty = computed<Games.Lax<Games.Loyalty>>(() => {
  return utilsPlugin.getApp()?.$store?.getters?.['game/loyalty'](GameKeyEnum.goat)
})
function renderApiOffers(
  apiData: Array<Games.Offer>,
  gameKey: Games.GameKeyType,
  gaContext: GaUtils.GaContext,
  pageOfferId = 0,
  moId = 0
): { offers: Array<Games.Offer>, pageOffer: Games.Offer | null } {
  const moGroupIdMap: Record<number, number> = {}
  let renderedOffers: Array<Games.Offer> = []
  let excludedIndex = -1
  let excluded: Games.Offer | null = null
  /**
   * grouping multiOffers;
   * set gcoinsLvl
   */
  const imgStorageUrl = games[gameKey].imgStorageUrl ?? ''
  apiData.forEach((item: Games.Offer, index) => {
    item.gameKey = gameKey
    item.gaContext = { ...(gaContext ?? {}) }
    if (item.items?.length) {
      for (const offerItem of item.items) {
        offerItem._amountShort = `${offerItem.amount}`
        if (offerItem.amount && offerItem.amount >= 10_000) {
          if (offerItem.amount >= 1000_000_000) {
            offerItem._amountShort = `${Math.round((offerItem.amount / 1000_000_000) * 100) / 100}G`
          } else if (offerItem.amount >= 1000_000) {
            offerItem._amountShort = `${Math.round((offerItem.amount / 1000_000) * 100) / 100}M`
          } else {
            offerItem._amountShort = `${Math.round((offerItem.amount / 1_000) * 100) / 100}k`
          }
        }
        if (imgStorageUrl.length) {
          if (offerItem.asset && offerItem.asset.slice(0, 4) !== 'http') {
            offerItem.asset = imgStorageUrl + offerItem.asset
          }
        }
      }
    }
    if (imgStorageUrl.length) {
      if (item.asset && item.asset.slice(0, 4) !== 'http') {
        item.asset = imgStorageUrl + item.asset
      }
      if (item.featured?.asset && item.featured.asset.slice(0, 4) !== 'http') {
        item.featured.asset = imgStorageUrl + item.featured.asset
      }
      if (item.featured?.mobileAsset && item.featured.mobileAsset.slice(0, 4) !== 'http') {
        item.featured.mobileAsset = imgStorageUrl + item.featured.mobileAsset
      }
    }
    if (pageOfferId && item?.id === pageOfferId) {
      excludedIndex = index
    }
    if (item?.moGroupId) {
      if (moGroupIdMap?.[item.moGroupId] === undefined) {
        item.multiOffers = [{ ...item }]
        item.isMultiOffer = true
        moGroupIdMap[item.moGroupId] = renderedOffers.push(item) - 1
      } else {
        renderedOffers[moGroupIdMap[item.moGroupId]].multiOffers?.push(item)
      }
    } else {
      renderedOffers.push(item)
    }
  })
  if (apiData?.[excludedIndex]) {
    excluded = apiData[excludedIndex]
  } else if (moId && moGroupIdMap[moId]) {
    excluded = renderedOffers[moGroupIdMap[moId]]
  }
  /**
   * sets the purchasable offer as root multiOffer
   */
  Object.entries(moGroupIdMap).forEach(([, index]) => {
    const validatedMultiOffer = renderedOffers[index]
    if (!!validatedMultiOffer?.moIsLocked || !!validatedMultiOffer?.isBought) {
      validatedMultiOffer?.multiOffers?.some((offer) => {
        if (!(!!offer?.moIsLocked || !!offer?.isBought)) {
          offer.multiOffers = validatedMultiOffer.multiOffers
          offer.isMultiOffer = true
          renderedOffers[index] = offer
          return true
        }
        return false
      })
    }
  })
  /**
   * remove expired offers or root multiOffers that are bought or locked
   * set pageOffer (used by offer page (null pageOffer means that the offer is no longer available (including expired);
   */
  const excludedMoGroupId = excluded?.moGroupId || 0
  excluded = null
  renderedOffers = renderedOffers.filter((filterOffer) => {
    /**
     * remove logic
     */
    if ((filterOffer?.expireSec || 0) < 0 || (filterOffer?.moGroupId && (filterOffer?.isBought || filterOffer?.moIsLocked))) {
      return false
    }
    /**
     * exclude logic
     */
    if (
      pageOfferId &&
      ((excludedMoGroupId && filterOffer?.moGroupId === excludedMoGroupId) ||
        (!excludedMoGroupId && pageOfferId === filterOffer.id))
    ) {
      excluded = filterOffer
      return false
    }
    return true
  })
  return { offers: renderedOffers, pageOffer: excluded }
}

function sortByIsBought(offers: Array<Games.Offer>) {
  offers.sort((a, b) => {
    if (b.isBought) {
      return -1
    }
    if (a.isBought) {
      return 1
    }
    return 0
  })
  return offers
}
function checkCart(apiData: Array<Games.Offer>, gameKey: Games.GameKeyType) {
  /**
   * cart refresh logic;
   * isLogged works only on gamePage so can't be used;
   * only offers on game pages are removed because there is only a complete list there
   */
  const isLoggedUser = !!utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(gameKey)?.token
  if (isLoggedUser && games[gameKey].enableCart) {
    const mapApiOffers: Record<number | string, Games.Offer> = {}
    apiData.forEach((item: Games.Offer) => {
      mapApiOffers[item?.id ?? ''] = item
    })

    const cart: Games.Offer[] = utilsPlugin.getApp()?.$store?.getters['game/gameCart'](gameKey)
    if (cart.length) {
      for (const cartIndex in cart) {
        const offerNewData = mapApiOffers?.[cart[cartIndex]?.id ?? 0]
        if ((!offerNewData?.id && !!routeGame.value?.gameKey) || offerNewData?.isFree || offerNewData?.isBought) {
          const cartItem = cart[cartIndex]
          gaRemoveFromCart(
            {
              items: [{
                item_id: String(cartItem.id),
                item_name: cartItem?.product || cartItem?.name,
                index: Number.parseInt(cartIndex || '0'),
                quantity: cartItem?.qty ?? 1,
                price: cartItem?.discount?.value || cartItem?.price?.value
              }],
              value: cartItem?.discount?.value || cartItem?.price?.value,
              currency: cartItem?.price?.currency,
              gs_game: gameKey,
              gs_route: String(utilsPlugin.getApp()?.$route.name),
              gs_section: gaSections.cartChecker,
              gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
            }
          )
          utilsPlugin.getApp()?.$store.commit('game/removeFromGameCart', [gameKey, cartIndex])
        } else {
          const diffQty = (cart[cartIndex]?.qty ?? 1) - (offerNewData?.allowQty ? offerNewData?.purchaseLimit ?? 1 : 1)
          if (diffQty > 0) {
            const cartItem = cart[cartIndex]
            gaRemoveFromCart(
              {
                items: [{
                  item_id: String(cartItem.id),
                  item_name: cartItem?.product || cartItem?.name,
                  index: Number.parseInt(cartIndex || '0'),
                  quantity: diffQty,
                  price: (cartItem?.discount?.value || cartItem?.price?.value || 0)
                }],
                value: (cartItem?.discount?.value || cartItem?.price?.value || 0) * diffQty,
                currency: cartItem?.price?.currency,
                gs_game: gameKey,
                gs_route: String(utilsPlugin.getApp()?.$route.name),
                gs_section: gaSections.cartChecker,
                gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
              }
            )

            offerNewData.qty = (offerNewData?.purchaseLimit ?? 1) || 1
          }
          utilsPlugin.getApp()?.$store.commit('game/updateGameCartOffer', [gameKey, offerNewData, cartIndex])
        }
      }
    }
  }
}

function showError(optionsNotify: Partial<NotificationOptions> | null = null) {
  let customClass = 'child-inherit-colors text-red-600 z-[999999] right-1/2 translate-x-1/2 large'
  if (optionsNotify?.appendTo) {
    customClass += ' absolute'
  }
  const defaultConfig = {
    title: 'Error',
    type: 'warning',
    customClass,
    message: 'Oops something went wrong',
    duration: 9000
  }
  utilsPlugin.getApp()?.$notify?.closeAll()
  utilsPlugin.getApp()?.$notify({ ...defaultConfig, ...(optionsNotify ?? {}) })
}

function apiGet(gameKey: Games.GameKeyType, endPoint: string, params: Record<string, any> | null = null, auth = true, apiPrefix = 'store/api/'): Promise<AxiosResponse> {
  const app = utilsPlugin.getApp()
  const game = getGameSettings(gameKey)
  let headers
  const token = app?.$store?.getters['game/token'](gameKey)?.token
  let loggedPreffix = ''
  if (game?.api) {
    if (auth && token) {
      loggedPreffix = 'user/'
      headers = { Token: token }
    }
    return app?.$axios.get(`${game.api}/${apiPrefix}${loggedPreffix}${endPoint}`, { params, headers })
  } else if (gameKey === GameKeyEnum.goat) {
    headers = { Authorization: app?.$store?.getters['game/token'](GameKeyEnum.goat)?.token }
    return app?.$axios.get(`${endPoint}`, { params, headers })
  }
  return new Promise(resolve => resolve({} as AxiosResponse))
}

function apiPost(gameKey: Games.GameKeyType, endPoint: string, params: Record<string, any> | null = null, auth = true, apiPrefix = 'store/api/'): Promise<AxiosResponse> {
  const app = utilsPlugin.getApp()
  const game = getGameSettings(gameKey)
  let headers
  const token = app?.$store?.getters['game/token'](gameKey)?.token
  let loggedPreffix = ''
  if (game?.api) {
    if (auth && token) {
      loggedPreffix = 'user/'
      headers = { Token: token }
    }
    return app?.$axios.post(`${game.api}/${apiPrefix}${loggedPreffix}${endPoint}`, params, { headers })
  } else if (gameKey === GameKeyEnum.goat) {
    headers = { Authorization: app?.$store?.getters['game/token'](GameKeyEnum.goat)?.token }
    return app?.$axios.post(`${endPoint}`, params, { headers })
  }
  return new Promise(resolve => resolve({} as AxiosResponse))
}

function catchError(error: Error | boolean | AxiosError) {
  if (error instanceof AxiosError) {
    utilsPlugin.getApp()?.$notify?.closeAll()
    utilsPlugin.getApp()?.$notify({
      title: 'Error',
      type: 'warning',
      customClass: 'child-inherit-colors text-red-600 z-[999999]',
      message: `${error?.response?.data?.message || error?.response?.data?.errors?.[0] || error}`,
      duration: 9000
    })
  } else if (error instanceof Error) {
    console?.error?.(error)
  }
}
function catchNotifyError(optionsNotify = {} as Record<string, any>) {
  return (error: Error | boolean | AxiosError) => {
    if (error instanceof AxiosError) {
      let customClass = 'child-inherit-colors text-red-600 z-[999999] right-1/2 translate-x-1/2 large'
      if (optionsNotify.appendTo) {
        customClass += ' absolute'
      }
      utilsPlugin.getApp()?.$notify?.closeAll()
      utilsPlugin.getApp()?.$notify({
        title: 'Error',
        type: 'warning',
        customClass,
        message: `${error?.response?.data?.message || error?.response?.data?.errors?.[0] || error}`,
        duration: 9000,
        offset: 100,
        ...(optionsNotify ?? {})
      })
    } else if (error instanceof Error) {
      console?.error?.(error)
    }
  }
}
function catchFormErrors(externalErrors: Record<string, any>, optionsNotify = {} as Record<string, any>) {
  return (error: Error | boolean | AxiosError) => {
    if (error instanceof AxiosError &&
      error?.response?.status &&
      error?.response?.status < 401 &&
      error.response?.data?.status === 'error' &&
      error.response?.data?.errors
    ) {
      let isMatchedFieldError = false
      let errorPopupMsg = ''
      const fieldErrors = error.response.data.errors
      Object.entries(fieldErrors).forEach(([fieldName, errorValue]) => {
        if (fieldName in externalErrors) {
          isMatchedFieldError = true
          externalErrors[fieldName] = errorValue
          delete fieldErrors[fieldName]
        }
      })
      errorPopupMsg = Object.entries(fieldErrors)
        .map(([, val]) => {
          return val
        })
        .join(', ')
      if (isMatchedFieldError) {
        errorPopupMsg = `Not all fields are valid! <br/>${errorPopupMsg}`
      }
      if (errorPopupMsg) {
        let customClass = 'child-inherit-colors text-red-600 z-[999999] right-1/2 translate-x-1/2 large'
        if (optionsNotify.appendTo) {
          customClass += ' absolute'
        }
        utilsPlugin.getApp()?.$notify({
          title: 'Error',
          type: 'warning',
          customClass,
          message: `${errorPopupMsg || ''}`,
          duration: 9000,
          offset: 100,
          ...(optionsNotify ?? {})
        })
      }
    } else {
      catchNotifyError(optionsNotify)(error)
    }
  }
}

function setLoyalty(gameKey: Games.GameKeyType, apiLayaltyData: Games.Lax<Games.ApiLoyaltyData>) {
  utilsPlugin.getApp()?.$store?.commit('game/setLoyalty', [gameKey, apiLayaltyData])
}
async function getBuyOfferFromApi(buyOffer: Games.Offer): Promise<{ offers: Array<Games.Offer>, pageOffer: Games.Offer | null }> {
  const gameKey = buyOffer?.gameKey
  let renderedApiBuyOffers: { offers: Array<Games.Offer>, pageOffer: Games.Offer | null } = { offers: [], pageOffer: null }
  if (gameKey) {
    await apiGet(gameKey, 'offers/game/')
      .then(({ data }) => {
        setLoyalty(gameKey, data?.storeUser)
        const id = String(buyOffer?.id)
        const moID = buyOffer?.moGroupId || 0
        const allApiOffers: Array<Games.Offer> = []
        if (data.bestDeals) {
          allApiOffers.push(...data.bestDeals)
        }
        if (data.offers) {
          allApiOffers.push(...data.offers)
        }
        renderedApiBuyOffers = renderApiOffers(
          allApiOffers,
          gameKey,
          buyOffer?.gaContext ?? {},
          Number.parseInt(id),
          moID
        )
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.status === 401) {
          if (gameKey) {
            utilsPlugin.getApp()?.$store?.commit?.('game/logOut', gameKey)
          }
        } else {
          catchError(err)
        }
      })
  }
  return renderedApiBuyOffers
}
const triggerRef = ref(0)
const isVisibleDocument = useDocumentVisibility()
const triggerApi = {
  triggerRef,
  callTrigger: (delay = 0) => {
    if (delay) {
      setTimeout(() => {
        triggerRef.value = ++triggerRef.value % 10
      }, delay)
    } else {
      triggerRef.value = ++triggerRef.value % 10
    }
  },
  initTriggerApi: () => {
    const isSsr = import.meta.env.SSR
    if (!isSsr) {
      checkBuildVersion(true)
      const timeout = localStorage?.getItem?.('devtimeout') ? Number.parseInt(String(localStorage?.getItem?.('devtimeout'))) : 60
      if (timeout !== 60) {
        // eslint-disable-next-line no-alert
        alert?.('devtimeout ENABLED !!!!!!!!!!')
      }
      const intervalID = setInterval(() => {
        if (!payPro.visibility.value && !visibilityPaymentMethod.value && isVisibleDocument.value !== 'hidden') {
          checkBuildVersion()
          triggerApi.callTrigger()
        }
      }, timeout * 1000)

      const route = useRoute()
      let currentRouteName = route.name
      let currentRoutePath = route.path
      watch(
        () => getGameToken(routeGame.value?.gameKey)?.token,
        () => {
          if (route.path === currentRoutePath) {
            triggerApi.callTrigger()
          }
        }
      )
      watch(
        () => route.path,
        () => {
          const routeName = String(route.name)
          if (routeName === currentRouteName) {
            triggerApi.callTrigger()
          }
          currentRouteName = routeName
          utilsPlugin.nextLoopEvent(300).then(() => {
            currentRoutePath = route.path
          })
        }
      )

      onUnmounted(() => {
        if (intervalID) {
          clearInterval(intervalID)
        }
      })
    }
  }
}

function buyFree(offer: Games.Offer, loadingRefer: VueRef<string>, context: BuyContext | null = null) {
  if (offer?.gameKey && offer?.id) {
    const currentOffer = { ...offer }
    loadingRefer.value = `${currentOffer.gameKey}_${currentOffer.id}`
    buyFreeDialog.value.offer = currentOffer
    const { gaBeginCheckout, gaPurchase } = gaUtils
    if (context) {
      gaBeginCheckout({
        ...(offer?.gaContext ?? {}),
        ...context.params,
        gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
      })
    }
    apiGet(currentOffer.gameKey, 'getfree/', { offer_id: currentOffer.id })
      .then(() => {
        if (context) {
          buyFreeDialog.value.visible = true
          triggerApi.callTrigger(100)
          gaPurchase({
            ...(offer?.gaContext ?? {}),
            ...context.params,
            gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
          })
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.status === 401) {
          logOut(currentOffer.gameKey, err)
        } else {
          catchError(err)
        }
      })
      .then(() => {
        loadingRefer.value = ''
      })
  }
}

const buyOffer: VueRef<Games.Offer | null> = ref(null)

function logOut(gameKey: Games.GameKeyLax, error: ApiError = {}) {
  if (gameKey) {
    if (gameKey === GameKeyEnum.goat) {
      utilsPlugin.getApp()?.$store?.commit?.('game/logOutAll')
    } else {
      utilsPlugin.getApp()?.$store?.commit?.('game/logOut', gameKey)
    }
    if (error?.response?.data?.ban) {
      utilsPlugin.getApp()?.$store?.commit?.('game/setLogBan', [gameKey, String(error?.response?.data?.ban)])
    }
  }
  buyOffer.value = null
}

function showCheckoutSingle(openFrom = '') {
  if (!buyOffer.value?.id || !buyOffer.value?.gameKey) {
    visibilityPaymentMethod.value = false
    return
  }
  const purchaseOffer = { ...buyOffer.value }
  loading.value = `${openFrom}${buyOffer.value?.gameKey}_${buyOffer.value?.id}`

  let idPageTemplate = 18545
  if (import.meta.env.VITE_PROD !== 'on') {
    idPageTemplate = 18544
  }
  const domain = `${window.location.protocol}//${window.location.host}`
  const customParams: Record<string, string> = {
    'page-template': `${idPageTemplate}`,
    'exfo': '742'
  }
  let redirect = window?.location?.origin || import.meta.env.VITE_HOST || 'https://store.gamesture.com'
  if (buyOffer.value?.gameKey && games[buyOffer.value.gameKey] && buyOffer.value?.id) {
    const route = utilsPlugin.getApp()?.$route.name
    if (buyOffer.value.moGroupId) {
      redirect += `/game/${games[buyOffer.value.gameKey].id}/offer/${buyOffer.value.id}?moGroupId=${buyOffer.value.moGroupId}`
    } else if (route === 'game-page' || route === 'game-product-page') {
      redirect += `/game/${games[buyOffer.value.gameKey].id}`
    }
  }
  const gameIcon = games?.[purchaseOffer.gameKey]?.icon || ''
  if (gameIcon) {
    customParams['x-game-logo'] = domain + gameIcon
  }
  const productLogo = purchaseOffer?.asset
  if (productLogo) {
    customParams['x-product-1-logo'] = productLogo
  }
  const purchaseSections = []
  if (purchaseOffer?.gaContext?.gs_section) {
    purchaseSections.push(purchaseOffer?.gaContext?.gs_section)
  }
  if (openFrom.slice(0, 7) === 'payment') {
    purchaseSections.push(gaSections.paymentDialog)
  }
  const { gaBeginCheckout } = gaUtils
  gaBeginCheckout({
    currency: purchaseOffer?.price?.currency || '',
    value: purchaseOffer?.discount?.value || purchaseOffer?.price?.value || 0,
    items: [
      {
        item_id: String(purchaseOffer?.id),
        item_name: String(purchaseOffer?.product || purchaseOffer?.name),
        index: 0,
        quantity: purchaseOffer?.qty ?? 1,
        price: purchaseOffer?.discount?.value || purchaseOffer?.price?.value || 0
      }
    ],
    gs_game: purchaseOffer?.gameKey,
    gs_route: purchaseOffer?.gaContext?.gs_route || String(utilsPlugin.getApp()?.$route.name),
    gs_section: purchaseSections.join(','),
    gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
  })

  apiPost(purchaseOffer.gameKey, 'purchase/', {
    products: [purchaseOffer?.hash],
    customFields: { ...customParams },
    offerids: [purchaseOffer?.id]
  })
    .then(({ data }) => {
      if (data?.url) {
        url.value = `${data.url}&x-cart=none&x-redirect_target=${redirect}`
        payPro.visibility.value = true
      } else {
        loading.value = ''
        showError({ message: 'Oops something went wrong :(' })
      }
    })
    .catch((err) => {
      loading.value = ''
      visibilityPaymentMethod.value = false
      catchError(err)
      if (err?.response?.status === 401 || err?.status === 401) {
        buyOffer.value = null
        logOut(purchaseOffer.gameKey, err)
        openLoginDialog(purchaseOffer.gameKey, {})
      } else {
        catchError(err)
      }
    })
}

function showCheckoutCart(openFrom = '', multi = false, cartGameKey: Games.GameKeyLax | '' = null) {
  let gameKey: Games.GameKeyType = ''
  const products: string[] = []
  const amounts: number[] = []
  const offerids: number[] = []
  const gaProducts: Games.Offer[] = []
  let idPageTemplate = 18543
  if (import.meta.env.VITE_PROD !== 'on') {
    idPageTemplate = 18539
  }
  const domain = `${window.location.protocol}//${window.location.host}`
  const customParams: Record<string, string> = {
    'page-template': `${idPageTemplate}`,
    'exfo': '742'
  }
  let redirect = window?.location?.origin || import.meta.env.VITE_HOST || 'https://store.gamesture.com'
  if (multi) {
    gameKey = routeGame.value?.gameKey ?? (cartGameKey || '')
    loading.value = openFrom
    const gameCart: Games.Offer[] = utilsPlugin
      .getApp()
      ?.$store
      ?.getters?.['game/gameCart']?.(gameKey)
    gaProducts.push(...gameCart)
    gameCart.forEach((cartOffer) => {
      products.push(cartOffer?.hash ?? '')
      amounts.push(cartOffer?.qty ?? 1)
      if (cartOffer?.id) {
        const id = typeof cartOffer.id === 'string' ? Number.parseInt(cartOffer.id) : cartOffer.id
        offerids.push(id)
      }
    })
    redirect += `/game/${games[gameKey].id}`
    const gameIcon = games?.[gameKey]?.icon || ''
    if (gameIcon) {
      customParams['x-game-logo'] = domain + gameIcon
    }
  } else {
    if (!buyOffer.value?.id || !buyOffer.value?.gameKey) {
      visibilityPaymentMethod.value = false
      return
    }
    loading.value = `${openFrom}${buyOffer.value?.gameKey}_${buyOffer.value?.id}`
    const purchaseOffer = { ...buyOffer.value }
    gaProducts.push({ ...purchaseOffer })
    gameKey = purchaseOffer.gameKey
    if (purchaseOffer.hash) {
      products.push(purchaseOffer.hash)
    }

    if (buyOffer.value?.gameKey && games[buyOffer.value.gameKey] && buyOffer.value?.id) {
      const route = utilsPlugin.getApp()?.$route.name
      if (buyOffer.value.moGroupId) {
        redirect += `/game/${games[buyOffer.value.gameKey].id}/offer/${buyOffer.value.id}?moGroupId=${buyOffer.value.moGroupId}`
      } else if (route === 'game-page' || route === 'game-product-page') {
        redirect += `/game/${games[buyOffer.value.gameKey].id}`
      }
    }
    if (purchaseOffer?.id) {
      const id = typeof purchaseOffer.id === 'string' ? Number.parseInt(purchaseOffer.id) : purchaseOffer.id
      offerids.push(id)
    }
    const gameIcon = games?.[purchaseOffer.gameKey]?.icon || ''
    if (gameIcon) {
      customParams['x-game-logo'] = domain + gameIcon
    }
    const productLogo = purchaseOffer?.asset
    if (productLogo) {
      customParams['x-product-1-logo'] = productLogo
    }
  }
  if (games[gameKey].imgStorageUrl) {
    customParams['x-img-storage'] = games[gameKey].imgStorageUrl
  }
  if (gaProducts.length) {
    let sumValue = 0
    const items = gaProducts.map((gaOffer, gaIndex) => {
      sumValue += gaOffer?.discount?.value || gaOffer?.price?.value || 0
      return {
        item_id: String(gaOffer?.id),
        item_name: String(gaOffer?.product || gaOffer?.name),
        index: gaIndex || 0,
        quantity: gaOffer?.qty ?? 1,
        price: gaOffer?.discount?.value || gaOffer?.price?.value || 0
      }
    })
    const purchaseSections = []
    if (gaProducts?.[0]?.gaContext?.gs_section && !multi) {
      purchaseSections.push(gaProducts?.[0]?.gaContext?.gs_section)
    }
    if (openFrom.slice(0, 7) === 'payment') {
      purchaseSections.push(gaSections.paymentDialog)
    }
    if (multi) {
      purchaseSections.push(cartDrawerGaContext.value?.gs_section || gaSections.cart)
    }
    const { gaBeginCheckout } = gaUtils
    gaBeginCheckout({
      currency: gaProducts?.[0]?.price?.currency || '',
      value: sumValue,
      items,
      gs_game: gaProducts?.[0]?.gameKey,
      gs_route: gaProducts?.[0]?.gaContext?.gs_route || String(utilsPlugin.getApp()?.$route.name),
      gs_section: purchaseSections.join(','),
      gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
    })
  }
  apiPost(gameKey, 'purchase/', {
    products,
    amounts,
    offerids,
    customFields: { ...customParams }
  })
    .then(({ data }) => {
      if (data?.url) {
        url.value = `${data.url}&x-redirect_target=${redirect}?cart=${gameKey}`
        payPro.visibility.value = true
      } else {
        loading.value = ''
        showError({ message: 'Oops something went wrong :(' })
      }
    })
    .catch((err) => {
      loading.value = ''
      visibilityPaymentMethod.value = false
      if (err?.response?.status === 401 || err?.status === 401) {
        buyOffer.value = null
        logOut(gameKey, err)
        openLoginDialog(gameKey, {})
      } else {
        catchError(err)
      }
    })
}

function showCheckout(openFrom = '', multi = false, cartGameKey: Games.GameKeyLax | '' = null) {
  if (multi || (buyOffer.value?.gameKey && games?.[buyOffer.value.gameKey]?.enableCart)) {
    showCheckoutCart(openFrom, multi, cartGameKey)
  } else {
    showCheckoutSingle(openFrom)
  }
}

function buy(offer: Games.Offer, loadingRefer: VueRef<string>, context: BuyContext | null = null) {
  if ((offer?.expireSec || 0) < 0 ||
    !!offer?.isBought ||
    !!offer?.moIsLocked
  ) {
    if (context) {
      context.gaMethod(
        {
          ...(offer?.gaContext ?? {}),
          ...context.params,
          gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account},` +
            `${((offer?.expireSec || 0) < 0) ? gaUtils.gaBuyBtnContext.offerExpired : (offer?.isBought ? gaUtils.gaBuyBtnContext.offerBought : gaUtils.gaBuyBtnContext.offerLocked)}`
        }
      )
    }
    return
  }
  buyOffer.value = offer
  if (!getGameToken(offer?.gameKey)) {
    if (isLoggedGoat.value && offer?.gameKey !== GameKeyEnum.goat) {
      if (context) {
        context.gaMethod(
          {
            ...(offer?.gaContext ?? {}),
            ...context.params,
            gs_context: `${gaUtils.gaBuyBtnContext.goat_account},${gaUtils.gaBuyBtnContext.connection_game_required}`
          }
        )
      }
      openConnectionDialog(offer?.gameKey, true)
    } else {
      const offerContext = { ...(offer?.gaContext ?? {}) }
      if (offerContext?.gs_section?.length) {
        offerContext.gs_section += `,${gaSections.buyBtn}`
      } else {
        offerContext.gs_section = gaSections.buyBtn
      }
      if (context) {
        context.gaMethod(
          {
            ...offerContext,
            ...context.params,
            gs_context: gaUtils.gaBuyBtnContext.not_logged_in
          }
        )
      }
      openLoginDialog(offer?.gameKey, offerContext)
    }
    return
  }
  if (offer?.isFree) {
    if (context) {
      context.gaMethod(
        {
          ...(offer?.gaContext ?? {}),
          ...context.params,
          gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account},${gaUtils.gaBuyBtnContext.offerFree}`
        }
      )
    }
    buyFree(offer, loadingRefer, context)
    return
  }

  if (offer?.id && offer.gameKey && games[offer.gameKey]?.enableCart && offer.price?.value) {
    visibilityPaymentMethod.value = true
    if (context) {
      context.gaMethod(
        {
          ...(offer?.gaContext ?? {}),
          ...context.params,
          gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account},` +
            `${gaUtils.gaBuyBtnContext.showStorePaymentDialog}`
        }
      )
    }
    return
  }
  if (offer?.gcoinsPrice) {
    visibilityPaymentMethod.value = true
    if (context) {
      context.gaMethod(
        {
          ...(offer?.gaContext ?? {}),
          ...context.params,
          gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account},` +
            `${gaUtils.gaBuyBtnContext.showStorePaymentDialog}`
        }
      )
    }
    return
  }
  if (offer?.id && offer.gameKey) {
    if (context) {
      context.gaMethod(
        {
          ...(offer?.gaContext ?? {}),
          ...context.params,
          gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account},${gaUtils.gaBuyBtnContext.beginCheckout}`
        }
      )
    }
    showCheckout()
  }
}

function showLogInDialog(clearBuyOffer = false, context: LoginContext) {
  if (clearBuyOffer) {
    buyOffer.value = null
  }
  openLoginDialog(context?.gs_game || null, context)
}

function checkBuildVersion(init = false) {
  const isSsr = import.meta.env.SSR
  const isDev = import.meta.env.DEV
  const host = import.meta.env.VITE_HOST || ''
  if (!isSsr && !isDev) {
    let deployVersion = localStorage?.getItem?.('deploy-version')
    if (deployVersion && deployVersion?.length > 50) {
      deployVersion = ''
      localStorage.setItem?.('deploy-version', deployVersion)
    }

    const axiosInst = axios.create()
    axiosInst.get(`${host}/build.txt`)
      .then((data) => {
        const buildTxt = data?.data
        if (buildTxt && buildTxt?.length < 50) {
          if (!init) {
            if (buildTxt && deployVersion && buildTxt !== deployVersion) {
              utilsPlugin.getApp()?.$notify({
                title: 'New version of the store is available',
                type: 'warning',
                customClass: 'child-inherit-colors text-orange-400 z-[999999]',
                dangerouslyUseHTMLString: true,
                message: `<div class="text-left">It is recommended to immediately <a class="text-gs-main-color underline cursor-pointer hover:text-orange-600 active:text-orange-500" onclick="location?.reload?.()" >refresh store</a>.</div>`,
                duration: 58000
              })
            }
          } else {
            localStorage?.setItem?.('deploy-version', buildTxt)
          }
        } else {
          if (Sentry?.getClient()) {
            Sentry.captureException(new Error('not valid buildTxt'), {
              extra: {
                init,
                buildTxt: (buildTxt?.length ? buildTxt?.substring(0, 50) : buildTxt) || '-',
                axios: true,
                msg: 'not valid buildTxt in checkBuildVersion (v1)'
              }
            })
          }
        }
      })
      .catch((error) => {
        if (Sentry?.getClient()) {
          Sentry.captureException(error, {
            extra: {
              init,
              errorData: {
                response: {
                  status: error?.response?.status,
                  data: typeof error?.response?.data === 'string'
                    ? error.response.data.substring(0, 50)
                    : JSON.stringify(error?.response?.data)
                },
                config: {
                  url: error?.config?.url
                },
                code: error?.code,
                message: error?.message
              },
              axios: true,
              msg: 'catch error in checkBuildVersion (v1)'
            }
          })
        }
      })
  }
}

function buyGCoins(offer: Games.Offer, loadingRefer: VueRef<string> | undefined = undefined) {
  if (offer?.gameKey && offer?.id) {
    const currentOffer = { ...offer }
    const { gaPurchase, gaSpendVirtualCurrency, gaHelperEnums, gaBeginCheckout } = gaUtils
    loading.value = `paymentMethod_${currentOffer.gameKey}_${currentOffer.id}`
    gaBeginCheckout(
      {
        ...(currentOffer?.gaContext ?? {}),
        currency: gaHelperEnums.gcoin,
        value: currentOffer?.gcoinsPrice || 0,
        items: [
          {
            item_id: String(currentOffer?.id),
            item_name: String(currentOffer?.product || currentOffer?.name),
            index: 0,
            quantity: currentOffer?.qty ?? 1,
            price: currentOffer?.gcoinsPrice || 0
          }
        ],
        gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
      }
    )
    if (loadingRefer) {
      loadingRefer.value = loading.value
    }
    buyFreeDialog.value.offer = currentOffer
    apiGet(currentOffer.gameKey, 'buyforgcoins/', { offer_id: currentOffer.id })
      .then(() => {
        gaPurchase(
          {
            ...(currentOffer?.gaContext ?? {}),
            currency: gaHelperEnums.gcoin,
            value: currentOffer?.gcoinsPrice || 0,
            items: [
              {
                item_id: String(currentOffer?.id),
                item_name: String(currentOffer?.product || currentOffer?.name),
                index: 0,
                quantity: currentOffer?.qty ?? 1,
                price: currentOffer?.gcoinsPrice || 0
              }
            ],
            gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
          }
        )
        gaSpendVirtualCurrency(
          {
            ...(currentOffer?.gaContext ?? {}),
            virtual_currency_name: gaHelperEnums.gcoin,
            value: currentOffer?.gcoinsPrice || 0,
            item_name: String(currentOffer?.product || currentOffer?.name),
            items: [
              {
                item_id: String(currentOffer?.id),
                item_name: String(currentOffer?.product || currentOffer?.name),
                index: 0,
                quantity: currentOffer?.qty ?? 1,
                price: currentOffer?.gcoinsPrice || 0
              }
            ],
            gs_context: `${isLoggedGoat.value ? gaUtils.gaBuyBtnContext.goat_account : gaUtils.gaBuyBtnContext.game_account}`
          }
        )
        visibilityPaymentMethod.value = false
        buyFreeDialog.value.visible = true
        triggerApi.callTrigger(100)
      })
      .catch((err) => {
        if (err?.response?.status === 401 || err?.status === 401) {
          logOut(currentOffer.gameKey, err)
        } else {
          catchError(err)
        }
      })
      .then(() => {
        loading.value = ''
        if (loadingRefer) {
          loadingRefer.value = loading.value
        }
      })
  }
}

function checkBuyOfferWithApi(loadingRefer: VueRef<string>) {
  if (buyOffer.value?.gameKey && buyOffer.value?.id) {
    getBuyOfferFromApi(buyOffer.value).then((renderedApiBuyOffers) => {
      const { offers, pageOffer: apiBuyOffer } = renderedApiBuyOffers
      if (apiBuyOffer && !apiBuyOffer?.isBought && buyOffer.value?.id === apiBuyOffer?.id) {
        apiBuyOffer.gaContext = buyOffer.value?.gaContext ? { ...buyOffer.value.gaContext } : undefined
        if (buyOffer.value?.price?.value === apiBuyOffer?.price?.value) {
          buyOffer.value = apiBuyOffer
          buy(apiBuyOffer, loadingRefer)
        } else {
          offerDialog.value.offer = apiBuyOffer
          offerDialog.value.visible = true
          showOfferUrl()
        }
        return
      }
      // replace offers
      if (offers.length) {
        for (const offer of offers) {
          if (offer?.repl && offer?.repl === buyOffer.value?.id) {
            offerDialog.value.offer = offer
            offerDialog.value.visible = true
            showOfferUrl()
            buyOffer.value = null
            showError({
              message: 'We have better offer for You.',
              customClass: 'notify-text-large z-[999999]',
              title: 'Notification'
            })
            return
          }
        }
      }
      if (apiBuyOffer?.isBought) {
        showError({
          message: 'Offer already bought by the logged in user.',
          customClass: 'notify-text-large z-[999999]',
          title: 'Notification'
        })
      } else {
        showError({
          message: 'Offer is no longer available.',
          customClass: 'notify-text-large z-[999999]',
          title: 'Notification'
        })
      }
      buyOffer.value = null
    })
  }
}

function addToGameCart(offer: Games.Offer) {
  utilsPlugin.getApp()?.$store?.commit('game/addToGameCart', [offer])
}

function getGoatApiUrl(endpoint: string) {
  const apiHost = import.meta.env.VITE_GOAT_API
  return `${apiHost}/api/${endpoint}/`
}

function goatDebugCheckTokens() {
  if (!isLoggedGoat.value || (isLoggedGoat.value && localStorage?.getItem('debug_goat') !== 'on')) {
    return false
  }
  const tokens: { [key: string]: Token } = utilsPlugin.getApp()?.$store?.getters?.['game/tokens']
  const goatTokenGames = tokens?.[GameKeyEnum.goat]?.games
  const problems: string[] = []
  const gamesLoyalty: Record<string, Games.Loyalty> = {}
  gameOrder.forEach((gameKey) => {
    const gameKeyLoyalty: Games.Lax<Games.Loyalty> = utilsPlugin.getApp()?.$store?.getters?.['game/loyalty'](gameKey)
    if (gameKeyLoyalty) {
      gamesLoyalty[gameKey] = gameKeyLoyalty
    }
    if (goatTokenGames?.[gameKey] !== undefined) {
      if (tokens?.[gameKey] === undefined) {
        problems.push(`Token game "${gameKey}" not found in alive connected`)
      } else if (String(goatTokenGames?.[gameKey]) !== String(tokens?.[gameKey]?.id)) {
        problems.push(`Token game "${gameKey} ID:${goatTokenGames?.[gameKey]}" is different from alive connected "${gameKey} ID:${tokens?.[gameKey]?.id}"`)
      } else if (gameKeyLoyalty?.id && String(gameKeyLoyalty.id || 0) !== String(goatTokenGames?.[gameKey])) {
        problems.push(`Token game "${gameKey} ID:${goatTokenGames?.[gameKey]}" is different from game storeUser data "${gameKey} ID:${gameKeyLoyalty?.id}"`)
      } else if (gameKeyLoyalty?.id && String(gameKeyLoyalty?.goatId || 0) !== String(tokens?.goat?.id)) {
        problems.push(`GOAT "ID:${tokens?.goat?.id}" is different from game ${gameKey} storeUser.goatId "${gameKeyLoyalty?.goatId}"`)
      } else if (gameKeyLoyalty?.id && !!gameKeyLoyalty?.gcoins) {
        problems.push(`Api game in storeUser data "${gameKey} ID:${gameKeyLoyalty?.id}" sends gcoins ${gameKeyLoyalty?.gcoins}`)
      }
    } else if (tokens?.[gameKey] !== undefined) {
      if (goatTokenGames?.[gameKey] === undefined) {
        problems.push(`Connected game (api alive) "${gameKey} ID:${tokens?.[gameKey].id}" not found in games of goat token`)
      } else if (gameKeyLoyalty?.id && String(gameKeyLoyalty?.goatId || 0) !== String(tokens?.goat?.id)) {
        problems.push(`GOAT "ID:${tokens?.goat?.id}" is different from game ${gameKey} storeUser.goatId "${gameKeyLoyalty?.goatId}"`)
      } else if (gameKeyLoyalty?.id && !!gameKeyLoyalty?.gcoins) {
        problems.push(`Api game in storeUser data "${gameKey} ID:${gameKeyLoyalty?.id}" sends gcoins ${gameKeyLoyalty?.gcoins}`)
      }
    }
  })
  if (problems.length) {
    console?.error('GOAT DEBUG - goatDebugCheckTokens', { tokens, goatTokenGames, gameOrder, problems, gamesLoyalty })
    utilsPlugin.getApp()?.$notify?.closeAll()
    utilsPlugin.getApp()?.$notify({
      title: 'GOAT DEBUG - goatDebugCheckTokens',
      type: 'error',
      message: `${problems.join(', ')}. <br> Goat token: ${tokens?.goat?.token}.`,
      duration: 0,
      offset: 0,
      dangerouslyUseHTMLString: true
    })
  }
}

function goatDebugOldTokens(oldTokens: string[] = []) {
  if (localStorage?.getItem('debug_goat') === 'on' && oldTokens.length > 0) {
    utilsPlugin.getApp()?.$notify?.closeAll()
    utilsPlugin.getApp()?.$notify({
      title: 'GOAT DEBUG - goatDebugOldTokens',
      type: 'error',
      message: `${oldTokens.join(', ')} send.`,
      duration: 0,
      offset: 0,
      dangerouslyUseHTMLString: true
    })
  }
}

async function goadLoadAliveData() {
  if (!isLoggedGoat.value) {
    return false
  }
  await apiGet(GameKeyEnum.goat, getGoatApiUrl('logged/alive')).then((response) => {
    if (!isLoggedGoat.value) {
      return false
    }
    if (response?.data?.status === 'ok') {
      const goatData: Token = utilsPlugin.getApp()?.$store?.getters?.['game/token']?.(GameKeyEnum.goat)
      if (goatData?.id) {
        if (response?.data) {
          response.data.id = goatData.id
        }
        if (response?.headers?.token?.length) {
          decodeGoatToken(response.headers.token, goatData.email || '')
        }
        setLoyalty(GameKeyEnum.goat, response?.data)
      }
      goatDebugCheckTokens()
    }
  }).catch((err) => {
    if (err?.response?.status === 401 || err?.status === 401) {
      logOut(GameKeyEnum.goat, err)
    } else {
      catchError(err)
    }
  })
}

function goatConnectionSkippedGames(skipped: string[]) {
  if (skipped?.length) {
    const gamesNames = skipped.map(idGame => games?.[idGame as Games.GameKeyType]?.name || idGame).join(', ')
    ElNotification({
      message:
          skipped.length > 1
            ? `<div class="text-lg">The ${gamesNames} player accounts are already connected to your GOAT account.<br> If you wish to change them, please contact our support team.</div>`.replace(/\b(The)\s+\1\b/gi, '$1')
            : `<div class="text-lg">The ${gamesNames} player account is already connected to your GOAT account. <br>If you wish to change it, please contact our support team.</div>`.replace(/\b(The)\s+\1\b/gi, '$1'),
      type: 'warning',
      offset: 100,
      duration: 0,
      customClass: 'right-1/2 translate-x-1/2 large absolute md:w-[550px] max-w-full',
      dangerouslyUseHTMLString: true
    })
  }
}

const gamesUtils = {
  isProd,
  list: games,
  gameOrder,
  gameLoggedOrder,
  listById,
  routeGame,
  isLogged,
  isLoggedGame,
  getGameSettings,
  setGameToken,
  getGameToken,
  getGameTokens,
  clearGameTokens,
  catchError,
  showError,
  apiGet,
  apiPost,
  buildDeepLink,
  renderApiOffers,
  sortByIsBought,
  getBuyOfferFromApi,
  setLoyalty,
  gameLoyalty,
  buyOffer,
  buy,
  logOut,
  showLogInDialog,
  showCheckout,
  triggerApi,
  isVisibleDocument,
  loading,
  buyGCoins,
  checkBuyOfferWithApi,
  addToGameCart,
  checkCart,
  getGoatApiUrl,
  GameKeyEnum,
  catchFormErrors,
  catchNotifyError,
  isLoggedGoat,
  getGoatData,
  goatLoyalty,
  isConnectedAllToGoat,
  isSomeConnectedToGoat,
  goadLoadAliveData,
  getOldGameTokens,
  goatDebugCheckTokens,
  goatDebugOldTokens,
  goatConnectionSkippedGames
}

export { gamesUtils }
